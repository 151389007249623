import React, {useContext} from 'react';
import {GlobalStateContext} from '../../context/GlobalThemeContextProvider';

interface LocationIconProps {
  lat?: number;
  lng?: number;
}

const LocationIcon: React.FC<LocationIconProps> = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90039 0.687988C3.75396 0.687988 0.400391 4.13099 0.400391 8.38799C0.400391 12.975 5.1361 19.3 7.0861 21.709C7.51468 22.237 8.29682 22.237 8.72539 21.709C10.6647 19.3 15.4004 12.975 15.4004 8.38799C15.4004 4.13099 12.0468 0.687988 7.90039 0.687988ZM7.90039 11.138C6.42182 11.138 5.22182 9.90599 5.22182 8.38799C5.22182 6.86999 6.42182 5.63799 7.90039 5.63799C9.37896 5.63799 10.579 6.86999 10.579 8.38799C10.579 9.90599 9.37896 11.138 7.90039 11.138Z"
        fill={theme == 'regular' ? '#FDC30A' : '#000'}
      />
    </svg>
  );
};

export default LocationIcon;
