import React, { useContext, useState } from 'react';

import { GlobalStateContext } from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import Map from '../components/map';
import HelpCenterDetailsModal from '../components/help-center-details-modal';
import classes from './help-center.module.css';

const HelpCenter: React.FC = () => {
  const { isDesktop } = useContext(GlobalStateContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDistrict, setSelectedDistrict] = useState<string>('');

  return (
    <Layout>
      <Container>
        <PageTitle title={isDesktop ? 'Центр обслугову\u00adвання користувачів' : 'ЦОК'} />

        <div className={classes.info}>
          <p>В ЦОК можна отримати інформацію щодо:</p>
          <ul>
            <li>укладення договорів про постачання електричної енергії;</li>
            <li>нарахувань та розрахунків за спожиту електроенергію;</li>
            <li>укладення договорів про реструктуризацію заборгованості за спожиту електроенергію.</li>
          </ul>
          <p>Графік роботи: 8:00-17:12 (понеділок-четвер), 8:00-16:12 (п’ятниця), 13.00-14.00 - обідня перерва.</p>
          <p>Графік особистого прийому громадян з 10:00-12:00 (вівторок, четвер).</p>
          <p>Для того, аби переглянути певну адресу ЦОК - натисніть на відповідний район.</p>
        </div>

        <Map setIsModalOpen={setIsModalOpen} setSelectedDistrict={setSelectedDistrict} />
      </Container>

      {isModalOpen && <HelpCenterDetailsModal selectedDistrict={selectedDistrict} setIsModalOpen={setIsModalOpen} />}
    </Layout>
  );
};

export default HelpCenter;
