import React, {useContext} from 'react';
import {GlobalStateContext} from '../../context/GlobalThemeContextProvider';

const PhoneIcon = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5408 17.5747C19.9235 19.4507 17.5048 20.3863 15.7768 20.2303C13.4168 20.017 10.8488 18.7653 8.89946 17.3866C6.03412 15.3599 3.35012 12.225 1.78612 8.89831C0.680785 6.54764 0.432788 3.65751 2.07679 1.51485C2.68479 0.722848 3.34212 0.299858 4.33012 0.250525C5.70079 0.183858 5.89279 0.967994 6.36346 2.18933C6.71412 3.10266 7.18212 4.03433 7.44345 4.98099C7.93279 6.74766 6.22212 6.8215 6.00612 8.2655C5.87145 9.17617 6.97546 10.3974 7.47412 11.0467C8.45146 12.3187 9.62612 13.4159 10.9515 14.2505C11.7101 14.7292 12.9355 15.5918 13.8048 15.1158C15.1435 14.3824 15.0168 12.1252 16.8888 12.8892C17.8581 13.2839 18.7968 13.8534 19.7221 14.3521C21.1528 15.1214 21.0861 15.9187 20.5408 17.5747C20.9488 16.3374 20.1328 18.8121 20.5408 17.5747Z"
        fill={theme == 'regular' ? '#FDC30A' : '#000'}
      />
    </svg>
  );
};

export default PhoneIcon;
