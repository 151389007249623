import React, {useContext} from 'react';
import {GlobalStateContext} from '../../context/GlobalThemeContextProvider';

const MailIcon = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9004 13.2802L0.900391 4.45514V15.8062H20.9004V4.45514L10.9004 13.2802ZM10.9014 10.6182L0.900391 1.78717V0.806152H20.9004V1.78717L10.9014 10.6182Z"
        fill={theme == 'regular' ? '#FDC30A' : '#000'}
      />
    </svg>
  );
};

export default MailIcon;
