import React, {Dispatch, SetStateAction, useContext, useRef, useEffect} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import LocationIcon from '../images/help-center-modal/location-icon';
import PhoneIcon from '../images/help-center-modal/phone-icon';
import MailIcon from '../images/help-center-modal/mail-icon';
import classes from './help-center-details-modal.module.css';

type HelpCenterDetailsResult = {
  allHelpCentersInfoYaml: {
    edges: {
      node: {
        id: string;
        name: string;
        adress: string;
        email: string;
        phone: string;
        map: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allHelpCentersInfoYaml {
      edges {
        node {
          id
          name
          adress
          email
          phone
          map
        }
      }
    }
  }
`;

interface HelpCenterDetailsModalProps {
  selectedDistrict: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const HelpCenterDetailsModal: React.FC<HelpCenterDetailsModalProps> = ({selectedDistrict, setIsModalOpen}) => {
  const data = useStaticQuery<HelpCenterDetailsResult>(query);
  const helpCentersDetails = data.allHelpCentersInfoYaml.edges.map(({node}) => node);
  const center = helpCentersDetails.find(item => item.id == selectedDistrict);

  const {theme, fontSize} = useContext(GlobalStateContext);
  const modalOverlay = useRef(null);

  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    if (modalOverlay.current === e.target) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', e => e.key === 'Escape' && setIsModalOpen(false));
    return () => document.removeEventListener('keydown', e => e.key === 'Escape' && setIsModalOpen(false));
  }, []);

  return (
    <section
      onClick={closeModal}
      ref={modalOverlay}
      className={clsx(
        classes.modal_overlay,
        theme == 'whiteOnBlack' && classes.black_on_white,
        fontSize !== 'regular' && classes.biger_font_size,
      )}
    >
      <div className={classes.modal}>
        <h2 className={classes.center_name}>{center?.name}</h2>
        <div className={classes.center_details}>
          <div className={classes.center_details_item}>
            <LocationIcon />
            <p>
              <b>Адреса:&nbsp;</b>
              {center?.adress}
            </p>
          </div>
          <div className={classes.center_details_item}>
            <PhoneIcon />
            <p>
              <b>Тел.:&nbsp;</b>
              {center?.phone}
            </p>
          </div>
          <div className={classes.center_details_item}>
            <MailIcon />
            <p>
              <b>Електронна пошта:&nbsp;</b>
              {center?.email}
            </p>
          </div>
        </div>

        <div className={classes.google_map} dangerouslySetInnerHTML={{__html: center?.map ? center.map : ''}}></div>
        <button className={classes.modal_close_btn} onClick={() => setIsModalOpen(false)}></button>
      </div>
    </section>
  );
};

export default HelpCenterDetailsModal;
